<template>
    <div class="page_num">
        <p @click="setPage(this.page - 1)" :class="this.page <= 1 || !this.page ? 'disable' : ''" >
            <img src="@/assets/images/sub/map/page_num_before.svg" alt="앞페이지로 이동 버튼" />
        </p>
        <div>
            <p v-if="this.searchedData.length <= this.maxShowList" class="current">1</p>
            <template v-else>
                <!-- Math.min( 모든 페이지 수 - 이전화면페이지들, 한 화면 최대 페이지수 )만큼 반복 -->
                <p class="nums"
                    v-for="i in Math.min(this.totalPage - this.beforePage, this.maxViewPage)"
                    :key="i"
                    :class="(this.beforePage + i) == this.page ? 'current' : ''"

                    @click="setPage(Math.floor(this.beforePage + i))"
                >
                    {{this.beforePage + i}}
                </p>
            </template>
        </div>
        <p @click="setPage(this.page + 1)" :class="!this.page || this.page >= this.totalPage ? 'disable' : ''">
            <img src="@/assets/images/sub/map/page_num_next.svg" alt="뒷페이지로 이동 버튼" />
        </p>
    </div>
</template>

<script>
export default {
    props : {
        maxShowPage : Number,
        maxShowList : Number,
        searchedData : Array,
    },
    computed : {
        page(){ // 페이지
            return this.$route.query.page*1;
        },
        totalPage(){    // 총 페이지 수
            return this.searchedData.length == 0 ? 0
                : Math.ceil(this.searchedData.length/this.maxShowList);
        },
        beforePage(){   // 이전화면 페이지들 개수
            return this.page <= this.maxViewPage ? 0
                : Math.floor((this.page-1)/this.maxViewPage) * this.maxViewPage;
        },
        maxViewPage(){
            return Math.min(this.maxShowPage, this.viewPage)
        },
    },
    data(){
        return {
            viewPage : this.maxShowPage,
        }
    },
    mounted(){
         // 창크기에 따라 페이지 조정
        this.getMaxViewPage();
        window.addEventListener('resize', this.getMaxViewPage);
    },
    unmounted(){
        window.removeEventListener('resize', this.getMaxViewPage);
    },
    methods : {
        // 페이지 변경
        setPage(num){
            // 0보단 크고, 최대 페이지 이하일 때
            if(num > 0 && num <= Math.ceil(this.searchedData.length/this.maxShowList)){
                this.$router.push({
                    name : this.$route.name,
                    query : {
                        page : num,
                    }
                });
            }
        },
        // 창 크기 별로 화면 페이지 수 조정
        getMaxViewPage(){
            if(window.innerWidth < 540){
                this.viewPage = 5;
            } else {
                this.viewPage = this.maxShowPage;
            }
        },
    }
}
</script>

<style>
</style>