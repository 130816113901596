<template>
    <div class="banner_bottom">
        <div class="search">
            <input type="search" placeholder="제목 또는 내용을 입력후 Enter" v-model="inputSearch" @keyup.enter="search"/>
            <button type="button" @click="search"><i class="fas fa-search"></i></button>
        </div>
    </div>

    <section class="list_content">
        <ol v-if="this.isFetched && this.searchedData.length == 0">
            <li class="default">
                <p>게시글이 없습니다</p>
                <p></p>
            </li>
        </ol>
        <ol v-else-if="this.isFetched">
            <!-- 채용정보 일때 -->
            <template v-if="this.whichBoard == 'employment'">
                <li v-for="(item, index) in this.slicedList" :key="index">
                    <p @click="openDetail(item)">{{item.title}}</p>
                    <p>{{ item.always_yn == 'true' ? '상시채용' : `${ dateFormat(item.start_date) } ~ ${ dateFormat(item.end_date) }`}}</p>
                    <p class="status" :class="item.state == '진행중' ? 'progress' : ''">{{item.state}}</p>
                </li>
            </template>
            
            <!-- 채용정보 아닐때 -->
            <template v-else>
                <li v-for="(item, index) in this.slicedList" :key="index"
                    @click="openDetail(item)"
                >
                    <p>{{item.title}}</p>
                    <p>{{ dateFormat(item.enrollment_date) }}</p>
                </li>
            </template>
        </ol>
        <Pagination
            v-if="isFetched"
            :maxShowPage="maxShowPage"
            :maxShowList="maxShowList"
            :searchedData="searchedData"
        />
    </section>
</template>

<script>
import Pagination from '@/components/Pagination';
import { dateFormat } from '@/utils/dateFormat';

export default {
    name: "listBoard",
    props: {
        whichBoard: String,
    },
    components: { 
        Pagination,
    },
    mixins: [ dateFormat ],
    computed: {
        slicedList(){
            return this.searchedData.slice((this.$route.query.page - 1)*this.maxShowList, (this.$route.query.page)*this.maxShowList)
        },
        maxPage(){
            return this.data.length == 0 ? 1 : Math.ceil(this.data.length/this.maxShowList);
        }
    },
    watch:{
        // 진입 시 페이지 page 쿼리 없거나 page<=0이면 page=1 쿼리 페이지로 교체 (최대는 fetchBoardData안에 설정해놓음)
        $route(to,from){
            if(to.path == from.path && (!to.query.page || to.query.page <= 0)){
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        page: 1,
                    }
                })
            }
        },
        // 검색 입력값 변경시 동작
        inputSearch(input){
            if(input == ''){
                this.searchedData = this.data;
                // 초기화시 1페이지로
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        page: 1,
                    }
                })
            }
        },
    },
    data(){
        return {
            isFetched: false,
            data: [],

            pk: '',

            inputSearch: '',
            searchedData: [],

            maxShowPage: 10,    //한 화면에 보일 최대 페이지수
            maxShowList: 10,   // 한 페이지에 보일 최대 리스트 수
        }
    },
    mounted(){
        this.fetchBoardData();
    },
	updated(){
		document.querySelector('.content_container').classList.remove('loading');
	},
    methods: {
        // 게시판 데이터 받아오기
        fetchBoardData(){
            document.querySelector('.content_container').classList.add('loading');
            const detailUrl = this.whichBoard == 'manageDisclosure' ? 'getManagedisclosure'   //경영공시
                        : this.whichBoard == 'employment' ? 'getQualification'  //채용정보
                        : this.whichBoard == 'notice' ? 'getNoticelist'  //공지사항
                        : this.whichBoard == 'dataRoom' ? 'getDataroom'  //서식자료실
                        : this.whichBoard == 'customerNotice' ? 'getCustomernotice'  //고객안내문
                        : 'getTermscredit'; // 약관 및 규정
            this.isFetched = false;
            
            this.axios.get(`/${detailUrl}`)
            .then(res=>{
                this.isFetched = true;
                if(res.data.success){
                    this.data= res.data.data;
                    this.searchedData = this.data;

                    // 사용자가 url을 임의로 최대보다 크게 설정한 경우 범위 안으로 변경
                    if(this.$route.query.page != 1 && this.$route.query.page > this.maxPage){
                        this.$router.replace({
                            name: this.$route.name,
                            query: {
                                page: this.maxPage,
                            }
                        })
                    }
                }
                else{
                    alert(res.data.message);
                }
            })
        },
        // 검색하기(검색 입력값 변경)
        search(){
            if(this.inputSearch == ''){
                this.searchedData = this.data;
            } else{
                const searchWords = this.inputSearch.split(' ');
                this.searchedData = this.data;

                // 단어 여러개 넣은 경우 조건에 모두 해당되도록
                searchWords.forEach(word => {
                    this.searchedData = this.searchedData.filter(item=> item.title.includes(word) );
                });

                // 검색 리스트는 1페이지로(없으면 뭔가 이상해짐)
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        page: 1,
                    }
                })
            }
        },
        // 게시물 클릭시 상세 페이지로 이동
        openDetail(item){
            this.pk = this.whichBoard == 'manageDisclosure' ? item.managedisclosure_pk   //경영공시
                    : this.whichBoard == 'employment' ? item.qualification_pk  //채용정보
                    : this.whichBoard == 'notice' ? item.noticelist_pk  //공지사항
                    : this.whichBoard == 'dataRoom' ? item.dataroom_pk  //서식자료실
                    : this.whichBoard == 'customerNotice' ? item.customernotice_pk  //고객안내문
                    : item.termscredit_pk // 약관 및 규정
            this.$router.push({
                name: 'DetailBoard',
                query: {
                    whichBoard: this.whichBoard,
                    page: this.$route.query.page,
                    pk: this.pk
                }
            });
        },
    }
}
</script>