<template>
    <div class="content_container notice_view">
        <div class="content">
            <div class="banner">
                <h1>고객안내문</h1>
            </div>
            
            <ListBoard whichBoard="customerNotice" />
        </div>
    </div>
</template>

<script>
import ListBoard from '@/components/ListBoard'

export default {
	name : 'CustomerNotice',
	components : {
		ListBoard,
	},
}
</script>

